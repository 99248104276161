import React, { useEffect } from "react"

import { Container, ThinContainer, SEO } from "../components/common"

const ProductHunt = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setTimeout(() => {
        window.location.href = "https://www.producthunt.com/posts/gradienta"
      }, 2000)
    }
  })
  return (
    <div>
      <SEO
        title="Multicolor CSS Gradients, JPG Downloads, 100% Free!"
        description="Use pure CSS gradient backgrounds for your next website or app, as a JPG image or CSS code, no attribute required!"
      />
      <Container>
        <ThinContainer>
          <p>Redirecting to producthunt...</p>
        </ThinContainer>
      </Container>
    </div>
  )
}

export default ProductHunt
